import logo from "./img/logo.png";

import programmation from "./img/programmation.png";
import plateforme from "./img/plateforme.png";



import adobe from "./img/suite-adobe.png";


import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="loader"></div>

        <div class="tv">
          <img src={logo} alt="Ponky Labs" title="Ponky Labs" class="logo" />

          <div class="content">
            <p>
              Graphiste et développeur,
              <br />
              Je peux vous aider dans votre communication digitale,
              <br />
              graphisme web et print, sites Internet, applications mobiles,
              <br /> animation vectorielle, réalité virtuelle...
            </p>
            <p>
              <img src={plateforme} alt="systemes d'exploitation" title="systemes d'exploitation"/>
            </p>
            
          </div>
        </div>
      </header>

      <h2>Programmation</h2>
      <img src={programmation}  alt="programmation" title="programmation"/><br/>
      <p>Développement de site web, web apps, applications mobiles Android et IOS.</p>
<div class="cat-graphisme">
      <h2>Graphisme & modélisation 3D</h2>

      <img src={adobe} alt="adobe suite" title="adobe suite" /><br/>

      <iframe
        src="https://magalerievr.com/pages/backoffice/index.php?user=guillaumeguarin&id_user=1"
        class="graphisme"         title="ma galerie vr"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
      <br/><p>Création de visuels print et web, kakémonos, flyers, affiches, maquettes graphiques, cartes de visite, publicités<br/>
      Design d'interfaces logiciels, applications mobiles et web</p>
      <p><a href="https://magalerievr.com/pages/backoffice/index.php?user=guillaumeguarin&id_user=1">www.magalerievr.com</a></p>

</div>
<div class="cat-dj">      <h2>V-Jaying</h2>
      <iframe class="dj"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/mMg_dGSmnOk"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
      <br />
<p>Production de musique electronique<br/>
<a href="https://www.youtube.com/channel/UCXwEEo2FZqkLAFQ2s1uU11Q">https://www.youtube.com/channel</a></p><br/>
<p>Visuels via un logiciel de génération de fichiers de visualisation pour winamp (projet personnel en vue.js)<br/>
      <a href="https://github.com/ponkyone/milkdrop-generator">
        milkdrop generator
      </a></p>
      </div>
<div class="cat-game">
      <h2>Gaming</h2>
<div class="game">
      <iframe
        class="birdvr"
        src="https://magalerievr.com/birdvr"
        title="birdvr"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
            <iframe
        class="zomelette"
        src="http://ponky.fr/lab/games/zomelette/jeu.html"
        title="zomelette"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
</div>
<p>Production de jeux et d'applications mobiles<br/>
<a href="https://play.google.com/store/apps/developer?id=Ponky">playstore</a> <a href="https://magalerievr.com/birdvr/play.html">Bird VR</a></p>

</div>



<footer><p>Merci de votre visite ! <br/><br/>Ponky Labs all right reserved</p></footer>

    </div>

  );
}

export default App;
